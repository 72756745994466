import React, { useCallback, useEffect, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import History from "../../components/History";
import InfoAboutComponent from "../../components/InfoAboutComponent";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomAutocompleteNew from "../../components/TextFields/CustomAutocompleteNew";
import CustomTextField from "../../components/TextFields/CustomTextField";
import { payers, types } from "../../constants";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import { ColumnStack, RowStack } from "../../theme/standarts_styles";

const statusOptions = [
  {
    value: "approved",
    label: "Согласовано",
  },
  {
    value: "rejected",
    label: "Отказано",
  },
];

const ApprovalStatement = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [history, setHistory] = useState([]);
  const navigate = useNavigate();
  const ref2 = useRef(null);
  const { statementId } = useParams();
  const { setStatement } = useActions();

  const { statement } = useSelector((state) => state.calendarReducer);

  const methods = useForm({
    mode: "onBlur",
  });

  const {
    handleSubmit,
    setError,
    formState: { isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      if (statement?.status === "new") {
        await $authHost.patch(
          `/calendar_rules/claim/${statementId}/update/`,
          data,
        );
      }

      if (statement?.status === "approved") {
        await $authHost.post(`/calendar_rules/claim/${statementId}/cancel/`, {
          comment: data.comment,
        });
      }
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/schedule/statements-approval");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleBack = () => {
    navigate(`/schedule/statements-approval`);
  };

  const getHistory = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/calendar_rules/history/all/?calendar_rules_id=${statementId}`,
      );
      const data = response.data.items;
      setHistory(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [statementId]);

  const getStatement = useCallback(async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(`/calendar_rules/${statementId}/`);
      const data = response.data;
      setStatement(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setStatement, statementId]);

  useEffect(() => {
    getStatement();
    getHistory();

    return () => {
      setStatement(null);
    };
  }, [getHistory, getStatement, setStatement]);

  return (
    <Card sx={{ mb: 2 }}>
      {loading && <LoadingIndicator />}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{
              p: 2,
            }}
          >
            <Typography variant="subtitle2">Согласовать</Typography>
            <IconButton color="primary" onClick={handleBack} sx={{ p: 0 }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />
          <Box p={2}>
            <RowStack>
              <ColumnStack customSpace={0.5} ref={ref2}>
                <Typography
                  align="left"
                  component="h2"
                  pb={1}
                  variant="miniHeader"
                >
                  Общее
                </Typography>
                <InfoAboutComponent
                  label="Тип заявки"
                  text={types[statement?.type]}
                />
                {statement?.type !== "fired" && (
                  <InfoAboutComponent
                    label="За чей счет"
                    text={payers[statement?.payer]}
                  />
                )}
                <InfoAboutComponent
                  label="Дата создания"
                  text={dayjs(statement?.created_at).format("DD.MM.YYYY")}
                />
                <InfoAboutComponent
                  label="Сотрудник"
                  text={statement?.user?.display_name}
                />
                <InfoAboutComponent
                  label="Отдел"
                  text={statement?.department?.name}
                />
                <InfoAboutComponent
                  label="Текст запроса"
                  text={statement?.request_text}
                />
                <InfoAboutComponent
                  label="Дата начала"
                  text={dayjs(statement?.start_date).format("DD.MM.YYYY")}
                />
                <InfoAboutComponent
                  label="Дата окончания"
                  text={dayjs(statement?.end_date).format("DD.MM.YYYY")}
                />
              </ColumnStack>
              <ColumnStack
                customSpace={0.1}
                sx={{
                  height: `${ref2?.current?.clientHeight}px`,
                  overflowY: "auto",
                }}
              >
                <Typography
                  align="left"
                  component="h2"
                  pb={1}
                  variant="miniHeader"
                >
                  История
                </Typography>
                <History history={history} />
              </ColumnStack>
            </RowStack>
            <RowStack>
              <ColumnStack customSpace={0.5}>
                <Typography
                  align="left"
                  component="h2"
                  pb={1}
                  pt={4}
                  variant="miniHeader"
                >
                  Действия
                </Typography>
                <CustomAutocompleteNew
                  disabled={statement?.status !== "new"}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  label="Статус"
                  name="status"
                  options={statusOptions}
                  required={statement?.status === "new"}
                  sx={{ width: "100%", mb: 2 }}
                  value="value"
                />
                <CustomTextField
                  disabled={
                    !(
                      statement?.status === "new" ||
                      statement?.status === "approved"
                    )
                  }
                  label="Комментарий"
                  multiline
                  name="comment"
                  registerOptions={{
                    required:
                      (statement?.status === "new" ||
                        statement?.status === "approved") &&
                      t("main.another.requiredField"),
                  }}
                  rows={2}
                  size="small"
                  sx={{ width: "100%", mb: 2 }}
                />
                <Stack
                  alignItems="flex-start"
                  direction="row"
                  justifyContent="flex-end"
                  sx={{ width: "100%" }}
                >
                  {cancelConfirm ? (
                    <>
                      <Typography
                        sx={{
                          width: "100%",
                          overflow: "visible",
                          whiteSpace: "normal",
                        }}
                        variant="body2"
                      >
                        {statement?.type === "overtime_work_days" ||
                        statement?.type === "working_holidays"
                          ? "Внимание! Если для указанного периода переработок были начислены отгулы, при аннулировании заявления они не будут отменены автоматически. Требуется контроль! Все равно аннулировать?"
                          : statement?.type === "day_off"
                            ? "На основании заявления для сотрудника был списан 1 отгул из бюджета переработок. Аннулировать заявление и отменить списание отгула?"
                            : "Вы уверены, что хотите аннулировать заявление?"}
                      </Typography>
                      <Button
                        color="primary"
                        size="small"
                        type="submit"
                        variant="text"
                      >
                        Да
                      </Button>
                      <Button
                        onClick={() => setCancelConfirm(false)}
                        size="small"
                        variant="contained"
                      >
                        Нет
                      </Button>
                    </>
                  ) : (
                    <>
                      {statement?.status === "new" ? (
                        <Button type="submit" variant="contained">
                          Сохранить
                        </Button>
                      ) : (
                        <Tooltip title="Заявление может быть аннулировано руководителем, если оно в статусе “Согласовано” и не входит в закрытый период табеля.">
                          <Button
                            color="primary"
                            disabled={statement?.status !== "approved"}
                            onClick={() => setCancelConfirm(true)}
                            size="small"
                            sx={{
                              "&.Mui-disabled": {
                                pointerEvents: "auto",
                              },
                            }}
                            variant="outlined"
                          >
                            Аннулировать
                          </Button>
                        </Tooltip>
                      )}
                    </>
                  )}
                </Stack>
              </ColumnStack>
              <ColumnStack />
            </RowStack>
          </Box>
        </form>
      </FormProvider>
    </Card>
  );
};

export default ApprovalStatement;
